<template>
  <div>
    {{ info }}
    <div v-if="currentFile">
      <div>
        <v-progress-linear v-model="progress" color="light-blue" height="25" reactive>
          <strong>{{ progress }} %</strong>
        </v-progress-linear>
      </div>
    </div>

    <v-row no-gutters justify="center" align="center">
      <v-col cols="8">
        <v-file-input show-size label="File input" @change="selectFile"></v-file-input>
      </v-col>

      <v-col cols="4" class="pl-2">
        <v-btn color="success" dark small @click="uploadFile">
          Upload
          <v-icon right dark>mdi-cloud-upload</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-alert v-if="message" border="left" color="blue-grey" dark>
      {{ message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'FileUpload',
  props: ['url', 'info'],
  data() {
    return {
      currentFile: undefined,
      progress: 0,
      message: '',
    }
  },
  methods: {
    selectFile(file) {
      this.progress = 0
      this.currentFile = file
    },
    async uploadFile() {
      if (!this.currentFile) {
        this.message = 'Nessun file selezionato'
        return
      }
      this.message = 'Attendi...'
      await this.$api.trainData
        .uploadDistintaTreno(this.url, this.currentFile, event => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
        .then(
          response => {
            this.message = response.data.message
          },
          error => {
            console.log('AXIOS ERROR', error, 'Message:', error.response)
            this.progress = 0
            this.message = this.getErrorMessage(error)
            if (this.messa) this.currentFile = undefined
          }
        )
    },
    clearData: function() {
      console.log('CLEAR ...')
      this.currentFile = null
      this.progress = 0
      this.message = null
    },
    getErrorMessage(error) {
      let message = 'Si è verificato  un errore'
      if (error.response && error.response.data) {
        message = error.response.data.message
      }
      if (message.startsWith('javax.ws.rs.BadRequestException: ')) message = message.substring('javax.ws.rs.BadRequestException: '.length)
      return message
    },
  },
}
</script>
