<template>
  <div>
    <TosListVuetify entityName="viaggiTreno" :pageTitle="title" :headers="headers" :defaultFilters="defaultFilters"
      v-bind:filterOnMounted="false" :prepareItemFunction="prepareItem" :excludeButtons="excludeButtons"
      :defaulOptions="defaulOptions" ref="tosList">
      <template #extraButtons>
        <v-btn icon title="Mostra viaggi eliminati" @click="toggleCancellati" small v-if="!mostraEliminati">
          <v-icon color="primary" medium>mdi-delete</v-icon>
        </v-btn>
        <v-btn icon title="Nascondi viaggi eliminati" @click="toggleCancellati" small v-if="mostraEliminati">
          <v-icon color="warning" medium>mdi-delete</v-icon>
        </v-btn>
        <v-btn v-if="viewBtnGeneraViaggi() && faseOperativa === 'pianificazione'" color="primary"
          @click="showDialogGeneraViaggi" small>
          {{ tLabel("Genera viaggi") }}
        </v-btn>
      </template>

      <template #customFilters="slotProps">
        <v-row>
          <v-col cols="2">
            <v-text-field :label="tLabel('Codice/Codice esterno/Nome')" v-model="slotProps.data.codiceCodiceEsternoNome"
              @keyup.enter="$emit('refreshTable');" clearable />
          </v-col>
          <v-col cols="2" v-if="isArrivo">
            <v-select ref="partenzaSelect" :label="tLabel('Provenienza')"
              v-model.number="slotProps.data.nodoPartenzaId" :items="listNodi" item-text="descrizione" item-value="id"
              @keyup.enter="$emit('enterOnSelect', 'partenzaSelect')" clearable />
          </v-col>
          <v-col cols="2" v-if="!isArrivo">
            <v-select ref="arrivoSelect" :label="tLabel('Destinazione')" v-model.number="slotProps.data.nodoArrivoId"
              :items="listNodi" item-text="descrizione" item-value="id"
              @keyup.enter="$emit('enterOnSelect', 'arrivoSelect')" clearable />
          </v-col>
          <v-col cols="2">
            <DateTimeAdapter 
              v-if="isArrivo" 
              :label="tLabel('ETA Da')" 
              v-model="slotProps.data.eta___GT"
              defaultTime="00:00:00" 
              @input="a => { slotProps.data.eta___GT = a; }" />
            <DateTimeAdapter 
              v-if="!isArrivo" 
              :label="tLabel('ETD Da')" 
              v-model="slotProps.data.etd___GT"
              defaultTime="00:00:00" @input="a => { slotProps.data.etd___GT = a; }"
              @keyup.enter="$emit('refreshTable');" />
          </v-col>
          <v-col cols="2">
            <DateTimeAdapter v-if="isArrivo" :label="tLabel('ETA a')" v-model="slotProps.data.eta___LT"
              defaultTime="23:59:59" @input="a => { slotProps.data.eta___LT = a; }" />
            <DateTimeAdapter v-if="!isArrivo" :label="tLabel('ETD a')" v-model="slotProps.data.etd___LT"
              defaultTime="23:59:59" @input="a => { slotProps.data.etd___LT = a; }" />
          </v-col>
          <v-col cols="2">
            <v-text-field :label="tLabel('Targa carro')" v-model="slotProps.data.targaCarro"
              @keyup.enter="$emit('refreshTable');" clearable />
          </v-col>
          <v-col cols="2">
            <v-text-field :label="tLabel('Codice UTI')" v-model="slotProps.data.codiceUTI"
              @keyup.enter="$emit('refreshTable');" clearable />
          </v-col>
        </v-row>
      </template>

      <template #editF="slotProps">
        <div v-if="slotProps.data" class="p-cardialog-content">
          <v-row>
            <v-col cols="2">
              <v-text-field disabled :label="tLabel('Codice')" v-model="slotProps.data.codice" />
            </v-col>
            <v-col cols="4">
              <v-text-field :label="tLabel('Codice esterno')" v-model.trim="slotProps.data.codiceEsterno"
                :error-messages="slotProps.errors.codiceEsterno" />
            </v-col>
            <v-col cols="4">
              <v-text-field :label="tLabel('Nome')" v-model.trim="slotProps.data.nome"
                :error-messages="slotProps.errors.nome" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-select :label="tLabel('Traccia')" v-model="slotProps.data.tracciaId" :items="listTraccePerDirezione"
                item-value="id" item-text="label" :error-messages="slotProps.errors.tracciaId"
                @change="showInfoTraccia(slotProps.data.tracciaId)" />
            </v-col>
            <v-col cols="4">
              <v-select :label="tLabel('Provenienza')" v-model="slotProps.data.nodoPartenzaId" :items="listNodi"
                v-if="slotProps.data.tracciaId == null" item-value="id" item-text="descrizione"
                :readonly="!isArrivo" :error-messages="slotProps.errors.nodoPartenzaId" />
              <template v-if="slotProps.data.tracciaId">
                {{ tLabel("Periodicità") }};
                <span v-html="formatPeriodicita(tracciaSelezionata.periodicita)" />
                <br />
                ETD
                {{ tracciaSelezionata.oraPartenza | formatTimeFromDate }} ETA
                {{ tracciaSelezionata.oraArrivo | formatTimeFromDate }}
              </template>
            </v-col>
            <v-col cols="4">
              <v-select :label="tLabel('Destinazione')" v-model="slotProps.data.nodoArrivoId" :items="listNodi"
                v-if="slotProps.data.tracciaId == null" item-value="id" item-text="descrizione"
                :readonly="isArrivo" :error-messages="slotProps.errors.nodoArrivoId" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <DateTimeAdapter label="ETD" v-model="slotProps.data.etd" :rules="[rules.required]"
                :error-messages="slotProps.errors.etd" />
            </v-col>
            <v-col cols="3">
              <DateTimeAdapter label="ETA" v-model="slotProps.data.eta" :rules="[rules.required]"
                :error-messages="slotProps.errors.eta" />
            </v-col>
          </v-row>
        </div>
      </template>

      <template #item.scarichi="slotProps"> {{ slotProps.data.numeroUtiScaricate }}/{{ slotProps.data.numeroUti }}
      </template>

      <template #item.carichi="slotProps"> {{ slotProps.data.numeroUtiCaricate }}/{{ slotProps.data.numeroUti }}
      </template>
      <template #item.statoPianificazione="slotProps"> <v-icon color="success"
          v-if="slotProps.data.statoPianificazione && slotProps.data.statoPianificazione === 'P'"> mdi-calendar-check
        </v-icon></template>
      <template #item.actions="slotProps">
        <template v-if="!slotProps.data.eliminato">
          <div v-if="faseOperativa === 'pianificazione'" class="css-group-and-align-element css-centered">
            <v-btn icon title="Modifica" @click="$emit('tableOpenItem', slotProps.data.id)">
              <v-icon color="primary" medium>mdi-pencil</v-icon>
            </v-btn>

            <v-btn icon title="Cancella"
              @click="doConfirm('Verranno cancellate tutte le unit associate e l\'operazione sarà irreversibile, confermi la cancellazione del treno?', 'toDelete', slotProps.data)">
              <v-icon color="primary" medium>mdi-delete</v-icon>
            </v-btn>

            <v-btn icon title="Importa" @click="onShowImport(slotProps.data)"
              :disabled="!slotProps.data.canUploadDistinta">
              <v-icon color="primary" medium>mdi-import</v-icon>
            </v-btn>
            <v-btn icon title="Esporta lista UTI" @click="onExportListUti(slotProps.data)"
              v-if="viewBtnExportUtiList() && !isArrivo">
              <v-icon color="primary" medium>mdi-export</v-icon>
            </v-btn>
            <v-btn icon title="Pianifica" @click="goPianificaTreno(slotProps.data)">
              <v-icon color="primary" medium>mdi-calendar-edit</v-icon>
            </v-btn>
            <v-btn icon title="Sopprimi" v-if="slotProps.data.statoViaggio.toLowerCase() !== 'soppresso'"
              @click="doConfirm('Confermi soppressione treno?', 'doSoppressione', slotProps.data)">
              <v-icon color="primary" medium>mdi-alpha-s-circle-outline</v-icon>
            </v-btn>

            <v-btn icon title="Annulla Soppressione" v-else
              @click="doConfirm('Vuoi annullare la soppressione treno?', 'doAnnullaSoppressione', slotProps.data)">
              <v-icon color="error" medium>mdi-alpha-s-circle</v-icon>
            </v-btn>

            <v-btn icon title="Riapertura" :disabled="disabledBtnRiaperturaViaggio(slotProps.data.statoOperativo)"
              @click="doConfirm('Confermi riapertura treno?', 'doRiapertura', slotProps.data)">
              <v-icon color="primary" medium>mdi-lock-open-outline</v-icon>
            </v-btn>
          </div>

          <div v-else class="css-group-and-align-element css-centered">
            <v-btn icon title="Lavora treno" @click="goLavoraTreno(slotProps.data)">
              <v-icon color="primary" medium>mdi-format-list-checks</v-icon>
            </v-btn>
          </div>
        </template>
      </template>
    </TosListVuetify>

    <v-dialog v-model="confirmDialogVisible" persistent max-width="450" header="Conferma">
      <v-card>
        <v-card-text>
          {{ confirmMessage }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="onConfirm" small>Si</v-btn>
          <v-btn @click="confirmDialogVisible = false" small>No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogImportVisible" persistent width="600">
      <v-card>
        <v-card-title>Importazione distinta treno</v-card-title>
        <v-card-text>
          <FileUpload ref="fileUploadComp" :url="uploadDistintaUrl" :info="importMessage"></FileUpload>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="gotoFiles" small>Visualizza files</v-btn>
          <v-btn @click="dialogImportVisible = false" small>Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogGeneraViaggiVisible" persistent width="600">
      <v-card>
        <v-card-title class="panelheader">{{ tLabel("Genera viaggi") }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <CalendarFilter label="Data inizio" v-model="generaViaggiDa" />
            </v-col>
            <v-col cols="6">
              <CalendarFilter label="Data fine" v-model="generaViaggiA" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialogGeneraViaggiVisible = false" small>{{ tLabel("Annulla") }}</v-btn>
          <v-btn color="success" @click="doGeneraViaggi" small>{{ tLabel("Genera viaggi") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import FileUpload from "primevue/fileupload";
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import CalendarFilter from "../../components/anagrafiche/CalendarFilter";
import DateTimeAdapter from "../../components/anagrafiche/DateTimeAdapter";
import FormatsMixins from "../../mixins/FormatsMixins";
import ToastMixin from "../../mixins/ToastMixin";
import FileUpload from "../../components/misc/FileUpload";
import railsMixins from "../../components/rails/mixins/railsMixins";
import moment from "moment";

export default {
  data() {
    return {
      dialogGeneraViaggiVisible: false,
      generaViaggiDa: null,
      generaViaggiA: null,
      listNodi: [],
      dialogImportVisible: false,
      uploadDistintaUrl: null,
      showLavoraTreno: null,
      importMessage: null,
      defaultFilters: {},
      confirmMethod: null,
      confirmData: null,
      confirmMessage: null,
      confirmDialogVisible: null,
      listTracce: [],
      tracciaSelezionata: {},
      nodoArrivo: {},
      nodoPartenza: {},
      mostraEliminati: false,
      currentRow: null,
      defaulOptions: null,
      headersAll: [
        { text: `${this.tLabel("Codice")}`, value: "codice" },
        { text: `${this.tLabel("Codice esterno")}`, value: "codiceEsterno" },
        { text: `${this.tLabel("Nome")}`, value: "nome" },
        { text: `${this.tLabel("Traccia")}`, value: "traccia.codice" },
        { text: `${this.tLabel("Provenienza")}`, value: "nodoPartenza.codice", visibleFor: () => this.isArrivo },
        { text: `${this.tLabel("Destinazione")}`, value: "nodoArrivo.codice", visibleFor: () => !this.isArrivo },
        {
          text: `${this.tLabel("ETD")}`,
          value: "etd",
          formatValue: "formatDateTimeFromDate",
          visibleFor: () => !this.isArrivo,
        },
        {
          text: `${this.tLabel("ETA")}`,
          value: "eta",
          formatValue: "formatDateTimeFromDate",
          visibleFor: () => this.isArrivo,
        },
        {
          text: `${this.tLabel("ATA")}`,
          value: "ata",
          formatValue: "formatDateTimeFromDate",
          visibleFor: () => this.isArrivo,
        },
        { text: `${this.tLabel("Circolazione")}`, value: "statoViaggio" },
        { text: `${this.tLabel("Lavorazione")}`, value: "statoOperativo" },
        { text: `${this.tLabel("Pianificato")}`, value: "statoPianificazione" },
        { text: `${this.tLabel("Num. carri")}`, value: "numeroCarri" },
        { text: `${this.tLabel("Carichi")}`, value: "carichi", sortable: false, visibleFor: () => !this.isArrivo },
        {
          text: `${this.tLabel("Scarichi")}`,
          value: "scarichi",
          sortable: false,
          visibleFor: () => this.isArrivo,
        },
        {
          text: `${this.tLabel("Viaggio prec")}`,
          value: "viaggioPrecedente.codice",
          visibleFor: () => !this.isArrivo,
        },
        {
          text: `${this.tLabel("Viaggio succ")}`,
          value: "viaggioSuccessivo.codice",
          visibleFor: () => this.isArrivo,
        },
        { text: `${this.tLabel("Vettore")}`, value: "traccia.vettore.codice" },
        { text: `${this.tLabel("MTO")}`, value: "traccia.vettore.mto" },


      ],
    };
  },
  props: {
    faseOperativa: {
      type: String,
      default: null,
    },
    title: { default: "" },
    isArrivo: {
      type: Boolean
    },
  },
  components: {
    FileUpload,
    TosListVuetify,
    CalendarFilter,
    DateTimeAdapter,
  },
  mixins: [FormatsMixins, ToastMixin, railsMixins],
  async mounted() {
    if (this.isArrivo) {
      this.defaulOptions = { sortBy: ["eta"] };
    } else {
      this.defaulOptions = { sortBy: ["etd"] };
    }

    const listNodiResp = await this.$api.get(this.$apiConfiguration.BASE_PATH + "nodi/list");
    this.listNodi = listNodiResp.data;
    this.listNodi.sort((a, b) => (a.descrizione > b.descrizione ? 1 : -1));

    this.setDefaultFilters();
    const listTracceResp = await this.$api.get(this.$apiConfiguration.BASE_PATH + "tracce/shortList");
    this.listTracce = [{ id: null, label: "" }, ...listTracceResp.data];

    if (this.faseOperativa === 'pianificazione') {
      this.$eventBus.$on("RailsEvent.VIAGGIO_MODIFICATO", (data) => this.onVoyageChanged(data));
    }
  },
  beforeDestroy() {
    this.$eventBus.$off("RailsEvent.VIAGGIO_MODIFICATO", this.onVoyageChanged(null));
  },
  watch: {
    $route() {
      this.setDefaultFilters();
    },
  },
  computed: {
    headers() {
      return this.headersAll.filter(col => col.visibleFor == null || col.visibleFor());
    },
    listTraccePerDirezione() {
      return this.listTracce.filter(t => t.isImport === undefined || t.isImport === (!this.isArrivo));
    },
    excludeButtons() {
      return { add: this.faseOperativa === "lavorazione" ? true : null };
    },
  },
  methods: {
    disabledBtnRiaperturaViaggio(statoOperativo) {
      if (statoOperativo.toLowerCase() !== "chiuso") {
        return "disabled";
      }
    },
    setDefaultFilters() {
      const nodiTerminal = this.listNodi.filter(item => item.isNodoTerminal == true);
      this.defaultFilters = { mostraNonEliminati: "false" };

      if (nodiTerminal.length > 0) {
        if (this.isArrivo) {
          this.nodoArrivo = nodiTerminal[0];
          this.defaultFilters.nodoArrivoId = this.nodoArrivo.id;
          this.defaultFilters.tipoViaggio___EQ = "ARRIVO";
          this.defaultFilters.eta___GT = moment().startOf('day').add(-3, 'days');
          console.log(this.defaultFilters.eta___GT)
          this.defaultFilters.eta___LT = moment().startOf('day').add(3, 'days');
          if (this.faseOperativa === "lavorazione") {
            this.defaultFilters.statoViaggio___EQ = "ARRIVATO";
          }
        } else {
          this.nodoPartenza = nodiTerminal[0];
          this.defaultFilters.nodoPartenzaId = this.nodoPartenza.id;
          this.defaultFilters.tipoViaggio___EQ = "PARTENZA";
          this.defaultFilters.etd___GT = moment().startOf('day').add(-3, 'days');
          console.log(this.defaultFilters.etd___GT)
          this.defaultFilters.etd___LT = moment().startOf('day').add(3, 'days');
          if (this.faseOperativa === "lavorazione") {
            this.defaultFilters.numeroCarri___GT = "1";
            // TODO
          }
        }
      }
    },
    prepareItem(item) {
      delete (item.ata); //evita errore Deserialization in POST
      delete (item.updatedAt);
      if (item.id != null) {
        this.showInfoTraccia(item.tracciaId);
        return;
      }
      const nodiTerminal = this.listNodi.filter(item => item.isNodoTerminal == true);
      if (nodiTerminal.length > 0) {
        if (this.isArrivo) {
          item.nodoArrivoId = nodiTerminal[0].id;
        } else {
          item.nodoPartenzaId = nodiTerminal[0].id;
        }
      }
    },
    goPianificaTreno(viaggio) {
      if (viaggio.tipoViaggio.toLowerCase() === "arrivo") {
        this.$router.push({
          path: "/callTreno/PianificazioneViaggioArrivo/" + viaggio.id,
        });
      } else {
        this.$router.push({
          path: "/callTreno/PianificazioneViaggioPartenza/" + viaggio.id,
        });
      }
    },
    goLavoraTreno(viaggio) {
      if (viaggio.tipoViaggio.toLowerCase() === "arrivo") {
        this.$router.push({
          path: "/callTreno/LavorazioneViaggioArrivo/" + viaggio.id,
        });
      } else {
        this.$router.push({
          path: "/callTreno/LavorazioneViaggioPartenza/" + viaggio.id,
        });
      }
    },
    showDialogGeneraViaggi() {
      this.dialogGeneraViaggiVisible = true;
      this.generaViaggiDa = new Date();
      this.generaViaggiA = new Date();
    },
    doGeneraViaggi() {
      this.dialogGeneraViaggiVisible = false;
      this.$api.trainData.generaViaggiTreno(this.generaViaggiDa, this.generaViaggiA).then(data => {
        const msg = "Viaggi generati :" + data.viaggiGenerati;
        this.showSuccess(msg);
        this.$emit("refreshTable");
      });
    },
    doConfirm(confirmMessage, confirmMethod, confirmData) {
      this.confirmMessage = confirmMessage;
      this.confirmData = confirmData;
      this.confirmMethod = confirmMethod;
      this.confirmDialogVisible = true;
    },
    onConfirm() {
      this.confirmDialogVisible = false;
      let method = this[this.confirmMethod];
      if (method == null) {
        console.warn("Confirm method null:", this.confirmMethod);
        return;
      }
      method(this.confirmData);
    },
    toggleCancellati() {
      this.mostraEliminati = !this.mostraEliminati;
      const current = this.defaultFilters.mostraNonEliminati;
      this.defaultFilters = { ...this.defaultFilters, mostraNonEliminati: current ? null : "false" };
      console.log("DEF FILTERS", this.defaultFilters);
    },

    async doSoppressione(data) {
      try {
        await this.updateViaggioTrenoStatoViaggioSopresso(data, "SOPPRESSO", true);
        this.showSuccess("Viaggio SOPPRESSO");
      } catch (e) {
        this.showError("Impossibile SOPPRIMERE il viaggio,");
        console.log(e);
      }
    },
    async doAnnullaSoppressione(data) {
      try {
        await this.updateViaggioTrenoStatoViaggioSopresso(data, "PROGRAMMATO", false);
        this.showSuccess("Anullato la soppressione del viaggio avenuto con successo");
      } catch (e) {
        this.showError("Impossibile annullare la soppressione del viaggio");
        console.log(e);
      }
    },
    annullaCancellazione(data) {
      alert("TODO annulla cancellazione id_" + data.id);
    },
    async updateViaggioTrenoStatoViaggioSopresso(data, stato, azione) {
      let id = data.id;
      await this.$api.trainData.updateViaggioTrenoStatoViaggioSopresso(id, stato, azione).then(resp => {
        Object.assign(data, resp);
      });
    },

    async toDelete(data) {
      let id = data.id;
      try {
        await this.$api.trainData.cancellazioneLogicaViaggio(id).then(resp => {
          Object.assign(data, resp);
        });
        this.showSuccess("Cancellazione del viaggio avenuto con successo");
      } catch (e) {
        this.showError("Errore: " + e.detailMessage);
        console.log(e);
      }
    },

    updateViaggioTrenoStatoViaggio(data, stato) {
      let id = data.id;
      this.$api.trainData.updateViaggioTrenoStatoViaggio(id, stato).then(resp => {
        Object.assign(data, resp);
      });
    },

    onShowImport(row) {
      this.dialogImportVisible = true;
      this.importMessage = "Importazione distinta viaggio " + row.codice;
      this.uploadDistintaUrl = "uploadDistinta/upload/" + row.id;
      this.currentRow = row;
      console.log("REFS", this.$refs.fileUploadComp);
      if (this.$refs.fileUploadComp) {
        console.log("try");
        this.$refs.fileUploadComp.clearData();
      }
    },

    async onExportListUti(row) {
      console.log("Todo export uti id:" + row.id);
      try {

        const response = await this.$api.trainData.exportListUti(row.id);
        const fileURL = window.URL.createObjectURL(new Blob([response], {}));
        const fileLink = document.createElement("a");
        fileLink.setAttribute("href", fileURL);
        fileLink.setAttribute("download", row.codice + "_ListaUti.xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (e) {
        this.showError("Generazione ListaUti non andata a buon fine");
        console.log(e);
      }
    },

    async showInfoTraccia(id) {
      if (id) this.tracciaSelezionata = await this.$api.get(this.$apiConfiguration.BASE_PATH + "tracce/" + id);
    },
    gotoFiles() {
      this.$router.push({
        path: "/filesList/" + this.currentRow.codice,
      });
    },

    async doRiapertura(data) {
      try {

        await this.$api.trainData.reopen(data.id).then(resp => {
          Object.assign(data, resp);
        });
        
        this.$emit("refreshTable");
        this.showSuccess("Viaggio Riaperto");
      } catch (e) {
        this.showError("Impossibile Riaprire il viaggio,");
        console.log(e);
      }
    },
    onVoyageChanged(data) {
      for (var i = 0; i < this.$refs.tosList.items.length; i++) {
        if (data == this.$refs.tosList.items[i].id) {
          console.log("--refresh page after event");
          clearTimeout(this.tableRefresh);
          this.tableRefresh = setTimeout(() => { this.$emit("refreshTable"); }, 500);
        }
      }

    }
  },
};
</script>

<style scoped>
@media (max-width: 1024px) {
  .p-datatable .p-sortable-column.p {
    border: 2px solid red;
  }

  .p-datatable .p-sortable-column.p-highlight {
    border: 3px solid red;
  }

  .errorMessage {
    color: red;
  }
}
</style>